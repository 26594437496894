export function derange(array) {
  const copy = array.slice()
  if (copy.length < 2) {
    return copy
  }

  let result = []
  let selectedIndex, i, copyLength
  let lastItemMoved = false

  for (i = 0, copyLength = copy.length - 1; i < copyLength; i++) {
    // when only two left and last item still in copy, swap and append to result
    if (copy.length === 2 && !lastItemMoved) {
      result = result.concat(copy.reverse().splice(0, 2))
      break
    }

    // Randomly select a remaining item in copy and make sure it's not going back in the same place
    do {
      selectedIndex = Math.floor(Math.random() * copy.length)
    } while (array.indexOf(copy[selectedIndex]) === result.length)

    // Add selected item to result
    result.push(copy.splice(selectedIndex, 1)[0])
    lastItemMoved ||= selectedIndex === copy.length
  }

  // Add the last item
  if (copy.length) result.push(copy[0])

  return result
}

import BlanksText from '../util/blanks_text'
import SortableWithSingleDropTargets from '../util/sortable_with_single_drop_targets'
import { gameState } from '../util/game_state'
import { setNotification } from '../util/set_notification'

up.compiler('[game-slide-blanks-text]', (element, { heading, text, skipToSolution, completeSlideUrl }) => {
  element.classList.add('h-100') // allow .blanks-text to stretch to full slide height
  const container = up.element.affix(element, '.blanks-text')

  const sourcesSpacer = up.element.affix(container, '.blanks-text--sources-spacer')
  up.element.affix(container, 'h3.blanks-text--sources-label', { text: 'Lösungsworte' })
  const sourceContainer = up.element.affix(container, '.blanks-text--sources')
  const controlsContainer = up.element.affix(container, '.blanks-text--controls')
  const destinationContainer = up.element.affix(container, '.blanks-text--text-body')

  const submitButton = up.element.affix(controlsContainer, 'button.btn.btn-primary', { type: 'button', text: 'Prüfen' })
  const destinationDropAreaSelector = '.blanks-text--drop-target'
  const nuggetSelector = '.blanks-text--nugget'
  let submitted = false

  const blanksText = new BlanksText(
    heading,
    text,
    sourceContainer,
    destinationContainer,
    destinationDropAreaSelector,
    nuggetSelector,
  )

  const dropTargets = element.querySelectorAll(destinationDropAreaSelector)
  const sortable = new SortableWithSingleDropTargets(sourceContainer, dropTargets, nuggetSelector)
  window.addEventListener('scroll', ensureStickySources)

  submitButton.addEventListener('click', onClickSubmit)
  up.on(dropTargets, 'click', handleClickOnDropTarget)
  gameState.submit.register(onClickSubmit)

  if (skipToSolution) {
    submit()
    displaySolution(element)
  }

  async function onClickSubmit() {
    submit()
    if (completeSlideUrl) {
      await up.request(completeSlideUrl, { method: 'POST' })
    }
    showSolutionButton()
    if (blanksText.isCompleted) {
      displaySuccessText()
    } else {
      displayFailureText()
    }
  }

  function handleClickOnDropTarget(event) {
    const dropArea = event.target
    const textNugget = getNugget(dropArea)

    if (textNugget) {
      // remove textNugget from dropArea and put it back to the source container
      sourceContainer.appendChild(textNugget)
    } else {
      up.layer.open({ content: modalForTextNuggets(), dismissable: true, onAccepted: (event) => { dropArea.appendChild(event.value) } })
    }
  }

  function getNugget(element) {
    if (element.matches(nuggetSelector)) {
      return element
    } else {
      return element.querySelector(nuggetSelector)
    }
  }

  function modalForTextNuggets() {
    // create a deep copy of the sourceContainer
    const originalTextNuggets = sourceContainer.querySelectorAll(nuggetSelector)
    const sourceContainerModal = up.element.createFromHTML(sourceContainer.outerHTML)

    // change styling of the source container copy
    sourceContainerModal.classList.remove('blanks-text--sources')
    sourceContainerModal.classList.add('blanks-text--sources-modal')

    up.on(sourceContainerModal, 'click', nuggetSelector, (event, clickedNugget) => {
      const originalTextNugget = up.util.find(originalTextNuggets, (textNugget) => {
        return textNugget.innerText === clickedNugget.innerText
      })
      up.layer.accept(originalTextNugget)
    })

    return sourceContainerModal
  }

  function submit() {
    submitButton.remove()
    sortable.destroy()
    gameState.stopGame()
    up.off(dropTargets, 'click', handleClickOnDropTarget)
    submitted = true
  }

  function destroy() {
    gameState.submit.unregister(onClickSubmit)
    if (!submitted) {
      gameState.stopGame()
    }
    sortable.destroy()
    window.removeEventListener('scroll', ensureStickySources)
  }

  function ensureStickySources() {
    window.requestAnimationFrame(() => {
      const viewportTop = up.element.get('main').offsetTop
      const padding = 15

      const sourcesBottom = sourceContainer.getBoundingClientRect().bottom
      const lastSourceItem = [...sourceContainer.childNodes].slice(-1)[0]
      const lastSourceBottom = lastSourceItem?.getBoundingClientRect()?.bottom || 0
      const spaceLeft = sourcesBottom - lastSourceBottom

      const { top, height } = sourcesSpacer.getBoundingClientRect()
      const newHeight = Math.max(0, viewportTop - top + padding)
      const heightDiff = newHeight - height

      if (spaceLeft > heightDiff) {
        sourcesSpacer.style.height = `${newHeight}px`
        sourcesSpacer.style.setProperty('--scrolled', (newHeight > 0) ? '1' : '0')
      }
    })
  }

  function showSolutionButton() {
    const showSolutionButton = up.element.affix(controlsContainer, 'button.btn.btn-primary', { type: 'button', text: 'Lösung anzeigen' })
    showSolutionButton.addEventListener('click', onClickShowSolution)
  }

  function displaySuccessText() {
    setNotification(element, 'success', 'Super — Lückentext gelöst! Klicke jetzt unten auf „Weiter“, um fortzufahren.')
  }

  function displayFailureText() {
    setNotification(
      element,
      'failure',
      'Schade — leider nicht ganz richtig! Schau dir bitte die Lösung an und klicke dann unten auf „Weiter“, um fortzufahren.',
    )
  }

  async function onClickShowSolution() {
    const solutionContainer = up.element.createFromSelector('.blanks-text--solution')
    up.element.affix(solutionContainer, 'h1', { text: 'Lösung' })
    const solutionBody = solutionContainer.appendChild(up.element.createFromHTML(destinationContainer.outerHTML))
    displaySolution(solutionBody)
    up.element.affix(solutionContainer, 'button.btn.btn-primary.mt-3', { type: 'button', text: 'Meine Antwort anzeigen', 'up-dismiss': true })

    await up.layer.open({ content: solutionContainer, size: 'large' })
  }

  function displaySolution(container) {
    const dropTargets = container.querySelectorAll(destinationDropAreaSelector)
    for (const dropArea of dropTargets) {
      dropArea.innerHTML = ''
      up.element.affix(dropArea, '.blanks-text--nugget', { text: dropArea.getAttribute('data-expected-text') })
      dropArea.classList.add('-solved')
    }
  }

  return destroy
})

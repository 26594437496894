// Animated icons are rendered using lottie-web's `lottie_light` player.
// Other implementations, like the "full" player, or @dotlottie's player use eval which is forbidden by our CSP.
//
// By default, icons play only while hovering a surrounding <a> or <button> tag.
// You may pass `autoplay: true` for icons which should always animate, e.g. when in a page header.
// Icons with autoplay will ignore hovering parents (i.e. they won't stop since they were asked to play automatically).

import loadScript from '../../shared/util/load_script'

const componentSelector = '.animated-icon'
const enterEvent = 'app:animated-icon:enter'
const leaveEvent = 'app:animated-icon:leave'

const hoverableTags = ['a', '.btn', 'tr']
const supportsHas = CSS.supports('selector:has(*)')
const hoverablesSelector = hoverableTags.map(tag => supportsHas ? `${tag}:has(${componentSelector})` : tag)

up.compiler(componentSelector, async (element, { path, scriptSource, autoplay = false, loop = true }) => {
  await loadScript(scriptSource)
  if (!element.isConnected) return // might have been removed from the DOM while waiting for loadScript

  const animatedIcon = new window.AnimatedIcon({ element, loop, autoplay, path })

  element.addEventListener(enterEvent, () => { animatedIcon.onEnter() })
  element.addEventListener(leaveEvent, () => { animatedIcon.onLeave() })

  up.destructor(element, () => {
    animatedIcon.destroy()
  })
})

up.compiler(hoverablesSelector, (element) => {

  const animatedIcon = element.querySelector(componentSelector)

  if (animatedIcon) {
    element.addEventListener('mouseenter', () => { up.emit(animatedIcon, enterEvent) })
    element.addEventListener('mouseleave', () => { up.emit(animatedIcon, leaveEvent) })
  }

})

import environment from '../../shared/util/environment'

up.compiler('.course-units-scroller', (element) => {

  const scrollContainer = element.querySelector('.course-units-scroller--tiles')
  const previousPageButton = element.querySelector('.course-units-scroller--page-button.-previous')
  const nextPageButton = element.querySelector('.course-units-scroller--page-button.-next')
  const behavior = environment.isTest ? 'auto' : 'smooth'

  let updateTimer

  function move(deltaX) {
    scrollContainer.scrollBy({ left: deltaX, behavior })
  }

  function updateButtonStates() {
    const atStart = scrollContainer.scrollLeft === 0
    const atEnd = scrollContainer.scrollWidth - scrollContainer.offsetWidth - scrollContainer.scrollLeft === 0

    previousPageButton.toggleAttribute('disabled', atStart)
    nextPageButton.toggleAttribute('disabled', atEnd)
  }

  function scheduleButtonUpdate() {
    // Scroll events are triggered often, so we debounce enabling/disabling buttons until the user stopped scrolling.
    window.clearTimeout(updateTimer)
    updateTimer = window.setTimeout(updateButtonStates, 50)
  }

  scrollContainer.addEventListener('scroll', scheduleButtonUpdate)
  previousPageButton.addEventListener('click', () => { move(-1 * scrollContainer.clientWidth) })
  nextPageButton.addEventListener('click', () => { move(scrollContainer.clientWidth) })

  scrollContainer.scrollTo(0, 0)
  updateButtonStates()

})

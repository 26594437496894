export default class BlanksText {
  constructor(heading, text, sourceContainer, destinationContainer, destinationDropAreaSelector, draggableElementSelector) {
    this.words = this.blankWordsFromText(text)
    this.blankSizer = this.createBlankSizer()
    this.textFragments = text.split(/\[.*?\]/)
    this.destinationContainer = destinationContainer
    this.destinationDropAreaSelector = destinationDropAreaSelector
    this.draggableElementSelector = draggableElementSelector
    this.createBlanksText(heading, destinationContainer, destinationDropAreaSelector)
    this.createDraggableElements(sourceContainer, draggableElementSelector)
  }

  createBlankSizer() {
    return new Array(this.maxLengthOfWords).fill('X').join('')
  }

  blankWordsFromText(text) {
    let matches = text.match(/\[.*?\]/g)
    matches = matches.map(match => match.substring(1, match.length - 1))
    return matches
  }

  createBlanksText(heading, container, dropAreaSelector) {
    up.element.affix(container, 'h3.blanks-text--subheading', { text: 'Lückentext' })
    if (heading) {
      up.element.affix(container, 'h1.blanks-text--heading', { text: heading })
    }

    let i = 0
    for (const textFragment of this.textFragments) {
      container.appendChild(document.createTextNode(textFragment))
      if (i < this.words.length) {
        up.element.affix(container, dropAreaSelector, {
          'data-expected-text': this.words[i++],
          'data-sizer-text': this.blankSizer,
        })
      }
    }
  }

  createDraggableElements(container, draggableElementsSelector) {
    const shuffledWords = this.words.sort((a, b) => 0.5 - Math.random()) // shuffle

    for (const word of shuffledWords) {
      up.element.affix(container, draggableElementsSelector, { text: word })
    }
  }

  get isCompleted() {
    let completed = true
    for (const dropArea of this.destinationContainer.querySelectorAll(this.destinationDropAreaSelector)) {
      const inputElement = dropArea.querySelector(this.draggableElementSelector)
      if (inputElement) {
        const match = inputElement.textContent === dropArea.dataset.expectedText
        if (match) {
          this.markAsCorrect(dropArea)
        } else {
          completed = false
          this.markAsIncorrect(dropArea)
        }
      } else {
        completed = false
        this.markAsIncorrect(dropArea)
      }
    }
    return completed
  }

  markAsCorrect(dropArea) {
    dropArea.classList.remove('-incorrect')
    dropArea.classList.add('-correct')
  }

  markAsIncorrect(dropArea) {
    dropArea.classList.remove('-correct')
    dropArea.classList.add('-incorrect')
  }

  get maxLengthOfWords() {
    let maxLength = this.words[0].length
    for (const word of this.words) {
      if (word.length > maxLength) {
        maxLength = word.length
      }
    }
    return maxLength
  }
}

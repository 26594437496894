import { derange } from './derangement'
import NuggetComparator from './nugget_comparator'

export default class GridGame {
  constructor(columnCount, gridContainer, gridSolution, sortableColumns, rowAttribute, columnIndexAttribute, gridElementSelector, draggableElementSelector) {
    this.columnCount = columnCount
    this.gridContainer = gridContainer
    this.sortableColumns = sortableColumns
    this.rowAttribute = rowAttribute
    this.columnIndexAttribute = columnIndexAttribute
    this.gridElementSelector = gridElementSelector
    this.draggableElementSelector = draggableElementSelector
    this.gridSolution = gridSolution
    this.shuffleGrid()
  }

  shuffleGrid() {
    for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
      if (this.sortableColumns[columnIndex]) {
        this.shuffleColumn(columnIndex)
      }
    }
  }

  shuffleColumn(columnIndex) {
    const draggableContainers = [...this.gridContainer.querySelectorAll(`[${this.columnIndexAttribute}="${columnIndex}"]`)]
    const draggables = draggableContainers.flatMap(container => container.querySelector(this.draggableElementSelector))
    const numberOfRows = draggableContainers.length
    const derangedRowIndexes = derange(Array.from(Array(numberOfRows).keys()))

    for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
      const draggable = draggables[rowIndex]
      const newRowIndex = derangedRowIndexes[rowIndex]
      const newContainer = draggableContainers[newRowIndex]

      newContainer.appendChild(draggable)
    }
  }

  get isCompleted() {
    let completed = true
    let gridIndex = 0
    for (const item of this.gridContainer.children) {
      if (this.isSortable(item)) {
        if (this.isCorrect(item, gridIndex)) {
          this.markRowAsCorrect(item.getAttribute(this.rowAttribute))
        } else {
          completed = false
          this.markRowAsIncorrect(item.getAttribute(this.rowAttribute))
        }
      }
      gridIndex++
    }
    return completed
  }

  isSortable(item) {
    const columnIndex = item.getAttribute(this.columnIndexAttribute)
    return this.sortableColumns[columnIndex]
  }

  isCorrect(item, gridIndex) {
    const actualItem = item.querySelector(this.gridElementSelector)
    const expectedItem = this.gridSolution.children[gridIndex].querySelector(this.gridElementSelector)

    return new NuggetComparator(actualItem).matches(expectedItem)
  }

  markRowAsCorrect(row) {
    this.eachRow(row, (element) => {
      if (!element.classList.contains('-incorrect')) {
        element.classList.add('-correct')
      }
    })
  }

  markRowAsIncorrect(row) {
    this.eachRow(row, (element) => {
      element.classList.remove('-correct')
      element.classList.add('-incorrect')
    })
  }

  eachRow(row, callback) {
    const rowElements = this.gridContainer.querySelectorAll(`[${this.rowAttribute}="${row}"]`)
    Array.from(rowElements).forEach(callback)
  }
}

import SortableWithSingleDropTargets from '../util/sortable_with_single_drop_targets'
import ImageAssignment from '../util/image_assignment'
import NuggetFinder from '../util/nugget_finder'
import { gameState } from '../util/game_state'
import { setNotification } from '../util/set_notification'

up.compiler('[game-slide-image-assignment]', (element, { name, skipToSolution, completeSlideUrl }) => {
  const sourceContainer = element.querySelector('.image-assignment--sources')
  const controlsContainer = element.querySelector('.image-assignment--controls')
  const destinationContainer = element.querySelector('.image-assignment--base-image')
  const explanationContainer = element.querySelector('.image-assignment--explanation')

  const submitButton = element.querySelector('.image-assignment--button')
  const destinationDropAreaSelector = '.image-assignment--drop-target'
  const nuggetSelector = '.image-assignment--nugget'
  let submitted = false

  for (let i = sourceContainer.children.length; i >= 0; i--) {
    // shuffle source nuggets
    sourceContainer.appendChild(sourceContainer.children[Math.random() * i | 0])
  }

  const imageAssignment = new ImageAssignment(
    sourceContainer,
    destinationContainer,
    destinationDropAreaSelector,
    nuggetSelector,
    explanationContainer,
  )

  const dropTargets = element.querySelectorAll(destinationDropAreaSelector)
  const sortable = new SortableWithSingleDropTargets(sourceContainer, dropTargets, nuggetSelector)

  submitButton.addEventListener('click', onClickSubmit)
  up.on(dropTargets, 'click', destinationDropAreaSelector, handleClickOnDropTarget)
  gameState.submit.register(onClickSubmit)

  if (skipToSolution) {
    submit()
    displaySolution()
  }

  function handleClickOnDropTarget(event, clickedDropArea) {
    const imageNugget = getNugget(clickedDropArea)

    if (imageNugget) {
      // remove imageNugget from dropArea and put it back to the source container
      sourceContainer.appendChild(imageNugget)
    } else {
      up.layer.open({
        content: modalForImageNuggets(),
        dismissable: true,
        onAccepted: (event) => {
          clickedDropArea.appendChild(event.value)
          up.emit(event.value, 'text:resize')
        },
      })
    }
  }

  function getNugget(element) {
    if (element.matches(nuggetSelector)) {
      return element
    } else {
      return element.querySelector(nuggetSelector)
    }
  }

  function modalForImageNuggets() {
    // create a deep copy of the sourceContainer
    const originalImageNuggets = sourceContainer.querySelectorAll(nuggetSelector)
    const sourceContainerModal = up.element.createFromHTML(sourceContainer.outerHTML)

    // change styling of the source container copy
    sourceContainerModal.classList.remove('image-assignment--sources')
    sourceContainerModal.classList.add('image-assignment--sources-modal')

    up.on(sourceContainerModal, 'click', nuggetSelector, (event, clickedNugget) => {
      const originalNugget = new NuggetFinder(originalImageNuggets).findByCopy(clickedNugget)
      up.layer.accept(originalNugget)
    })

    return sourceContainerModal
  }

  async function onClickSubmit() {
    submit()
    if (completeSlideUrl) {
      await up.request(completeSlideUrl, { method: 'POST' })
    }
    showSolutionButton()
    explanationContainer.removeAttribute('hidden')
    if (imageAssignment.isCompleted) {
      displaySuccessText()
    } else {
      displayFailureText()
    }
  }

  function submit() {
    submitButton.remove()
    sortable.destroy()
    gameState.stopGame()
    up.off(dropTargets, 'click', destinationDropAreaSelector, handleClickOnDropTarget)
    submitted = true
  }

  function destroy() {
    gameState.submit.unregister(onClickSubmit)
    if (!submitted) {
      gameState.stopGame()
    }
    sortable.destroy()
  }

  function showSolutionButton() {
    const showSolutionButton = up.element.affix(controlsContainer, 'button.btn.btn-primary', { type: 'button', text: 'Lösung anzeigen' })
    showSolutionButton.addEventListener('click', displaySolutionInModal)
  }

  function displaySuccessText() {
    setNotification(element, 'success', `Gut gemacht, ${name}! Du kennst die richtigen Antworten.`)
  }

  function displayFailureText() {
    setNotification(
      element,
      'failure',
      `Leider nicht ganz richtig, ${name}! Sieh dir die Lösung genau an.`,
    )
  }

  async function displaySolutionInModal() {
    const solutionContainer = up.element.createFromSelector('.image-assignment--solution')
    up.element.affix(solutionContainer, 'h1', { text: 'Lösung' })
    imageAssignment.buildSolution(solutionContainer)
    up.element.affix(solutionContainer, 'button.btn.btn-primary.mt-3', { type: 'button', text: 'Meine Antwort anzeigen', 'up-dismiss': true })

    await up.layer.open({ content: solutionContainer, size: 'large' })
  }

  function displaySolution() {
    destinationContainer.remove()
    imageAssignment.buildSolution(element)
    element.classList.add('-only-solution')
  }

  return destroy
})

import getDeviceType from '../util/device_type'
import QrScanner from 'qr-scanner'
import environment from '../../shared/util/environment'

up.compiler('.layout', async (element) => {
  let debounceTimeout
  const debounceDelay = 100

  function update() {
    const deviceType = getDeviceType()
    const deviceClass = `-${deviceType}-device`
    const otherDeviceClasses = [...element.classList].filter(className => className.match(/^-\w+-device$/) && className !== deviceClass)

    element.classList.remove(...otherDeviceClasses)
    element.classList.add(deviceClass)
  }

  function debouncedUpdate() {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(update, debounceDelay)
  }

  const resizeObserver = new ResizeObserver(debouncedUpdate)
  resizeObserver.observe(element)

  const hasCamera = await QrScanner.hasCamera()
  element.classList.toggle('-has-camera', hasCamera || environment.isFeatureSpec)

  up.destructor(element, () => {
    resizeObserver.disconnect()
  })

})

import Question from '../util/question'
import { gameState } from '../util/game_state'

up.compiler('[question]', (element, { name, skipToSolution, completeSlideUrl }) => {
  const question = new Question(element, name)
  const button = element.querySelector('[question--submit-button]')
  const choiceButtons = element.querySelectorAll('[question--choice-button]')
  const choices = element.querySelector('[question--choices]')

  let submitted = false

  up.on(button, 'click', onClickSubmit)
  up.on(choiceButtons, 'click', onClickChoice)
  gameState.submit.register(onClickSubmit)

  if (skipToSolution) {
    submit()
    question.skipToSolution()
  }

  async function onClickChoice() {
    checkChoice(this.getAttribute('answer_id'))
    onClickSubmit()
  }

  function checkChoice(answerId) {
    const choiceCheckbox = choices.querySelector(`[id="${answerId}"]`)
    choiceCheckbox.checked = true
  }

  async function onClickSubmit() {
    submit()
    if (completeSlideUrl) {
      await up.request(completeSlideUrl, { method: 'POST' })
    }
    question.checkAnswers()
  }

  function submit() {
    button.remove()
    gameState.stopGame()
    submitted = true
  }

  return function destroy() {
    gameState.submit.unregister(onClickSubmit)
    if (!submitted) {
      gameState.stopGame()
    }
  }
})

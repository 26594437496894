import isMobile from 'ismobilejs'

let installPrompt

window.addEventListener('beforeinstallprompt', (event) => {
  // If it is possible to install the app on the user's device, the "beforeinstallprompt" event is thrown.
  // We save the event in a variable and use it to display the corresponding prompt when the user clicks on the "install" button
  event.preventDefault()
  installPrompt = event

  // It may happen that the event is thrown after the compiler has already been executed. Therefore, we throw a global event 'app:a2hs:init' to
  // signal the compiler that the UI element should now be displayed.
  up.emit('app:a2hs:init')
})

up.compiler('[add-to-homescreen]', (element, { iosDialog, hideAddToHomescreen }) => {
  const installButton = element.querySelector('[add-to-homescreen--button]')

  function setVisibility() {
    if (hideAddToHomescreen) {
      element.hidden = true
    } else if (isMobile().apple.device) {
      element.hidden = false
      installButton.addEventListener('click', () => {
        up.layer.open({ content: iosDialog, size: 'small' })
      })
    } else if (isMobile().any && installPrompt) {
      element.hidden = false
      installButton.addEventListener('click', () => {
        showInstallPrompt()
      })
    }
  }

  function showInstallPrompt() {
    installPrompt.prompt()
    installPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        element.hidden = true
      }
    })
  }

  setVisibility()
  return up.on('app:a2hs:init', setVisibility)
})

up.compiler('[code-search]', (element) => {

  const button = element.querySelector('button')
  const input = element.querySelector('input[type="text"]')

  input.addEventListener('input', onInput)
  element.addEventListener('submit', onSubmit)

  function onInput() {
    enableOrDisable()
  }

  function onSubmit(event) {
    event.preventDefault()
    const value = getValue()

    if (value) {
      up.request(`/c/${getValue()}.json`).then(onSuccess).catch(onError)
    } else {
      input.focus()
    }
  }

  function onSuccess(response) {
    up.visit(response.json.url)
    input.value = ''
  }

  function onError(response) {
    alert(response.json.error || 'Hoppla, das hat nicht geklappt! Bitte versuche es später nochmal.')
    input.focus()
  }

  function enableOrDisable() {
    // If no value is entered, the submit button should appear disabled, but still be clickable.
    // When users click the "disabled" button, we focus the input instead.
    const disabled = !getValue()
    button.classList.toggle('disabled', disabled)
    button.classList.toggle('pe-auto', disabled)
  }

  function getValue() {
    return input.value.trim()
  }

  enableOrDisable()

})

up.compiler('[foldable]', (element) => {

  const foldableTriggers = element.querySelectorAll('[foldable--trigger]')
  const foldableContent = element.querySelector('[foldable--content]')
  const foldableIconExpanded = element.querySelector('[foldable--icon-expanded]')
  const foldableIconCollapsed = element.querySelector('[foldable--icon-collapsed]')

  foldableTriggers.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      up.element.toggle(foldableContent)
      up.element.toggle(foldableIconExpanded)
      up.element.toggle(foldableIconCollapsed)
      if (up.element.isVisible(foldableContent)) {
        up.reveal(foldableContent, { padding: 30 })
      }
    })
  })

})

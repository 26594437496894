up.compiler('[image-in-lightbox]', (element, { url, showButton = true }) => {

  if (element.closest('.-draggable')) return

  let lightbox

  const container = up.element.affix(element.parentElement, '.lightbox-image-container')
  container.appendChild(element)

  if (showButton) {
    const button = up.element.createFromHTML(`
      <button type="button" class="btn btn-circle btn-light lightbox-image-container--button">
        <span class="app-icon -open-lightbox"></span>
      </button>
    `)
    container.appendChild(button)
  }

  function openLightbox() {
    lightbox ??= buildLightbox()
    up.element.setAttrs(lightbox, { open: true })
  }

  function closeLightbox() {
    up.element.setAttrs(lightbox, { open: false })
  }

  function destroyLightbox() {
    lightbox?.remove()
  }

  function buildLightbox() {
    lightbox = up.element.createFromHTML(`
      <div class="lightbox">
        <div class="lightbox--content">
          <img src="${url || element.src}">
        </div>
      </div>
    `)
    document.body.appendChild(lightbox)
    lightbox.addEventListener('click', closeLightbox)
    return lightbox
  }

  container.addEventListener('click', openLightbox)

  return destroyLightbox

})

import { trackEvent } from '../util/analytics'

up.compiler('[read-aloud]', async (element, { autoPlay, saveSettingUrl, setPagePlayedUrl }) => {

  const TRACK_PAGE_READ_ALOUD_COUNTS = [2, 5]

  const startButtonSelector = '[read-aloud--start-button]'
  const stopButtonSelector = '[read-aloud--stop-button]'
  const audios = element.querySelectorAll('audio')

  if (audios.length < 1) {
    return
  }

  let playing = false
  let allAudiosPlayed = false
  let userPressedStop = false
  let playCountAfterFirstStop = 0
  let playCountAfterFirstFinish = 0
  let currentAudio
  const audioPlayingClass = 'audio-playing'
  const highlightClass = 'highlight'

  async function onClickButton() {
    const requestedPlayback = !playing

    if (requestedPlayback) {
      await play()
      trackEvent('read-aloud', 'click-play')
      if (allAudiosPlayed && playCountAfterFirstFinish === 1) {
        trackEvent('read-aloud', 'play-again-after-reading-finished')
      } else if (userPressedStop && playCountAfterFirstStop === 1) {
        trackEvent('read-aloud', 'play-again-after-stopping')
      }
    } else if (currentAudio) {
      stopAudio(currentAudio)
      trackEvent('read-aloud', 'click-stop')
      userPressedStop = true
    }

    updateVisibleButtonText()
    saveAutoPlaySetting(requestedPlayback)
  }

  async function onAudioEnded(audio, index) {
    const waitMs = audio.dataset.waitAfter
    if (waitMs) {
      await new Promise((resolve, reject) => {
        up.util.timer(waitMs, resolve)
      })
    }
    stopAudio(audio)

    const nextAudio = audios[index + 1]
    if (nextAudio) {
      await playAudio(nextAudio)
    } else {
      allAudiosPlayed = true
    }

    updateVisibleButtonText()
  }

  function updateVisibleButtonText() {
    element.querySelectorAll(startButtonSelector).forEach((button) => { button.hidden = playing })
    element.querySelectorAll(stopButtonSelector).forEach((button) => { button.hidden = !playing })
  }

  async function play({ throwError = false } = {}) {
    trackPlayCountAfterFirstStop()
    trackPlayCountAfterFirstFinish()

    const played = await playAudio(audios[0], { throwError })

    if (played) {
      up.request(setPagePlayedUrl, { method: 'PATCH' })
        .then(afterSetPagePlayed)
    }
  }

  async function playAudio(audio, { throwError = false } = {}) {
    if (currentAudio) {
      // avoid playing multiple audios at the same time
      stopAudio(currentAudio)
    }

    if (!audio.isConnected) {
      return
    }

    audio.currentTime = 0
    let playing = false

    try {
      await audio.play()
      playing = true

    } catch (error) {
      if (throwError) {
        throw error
      } else {
        showPlayErrorDialog()
        trackEvent('read-aloud', 'play-error', error.message)
      }
    }

    setPlaying(audio, true)
    currentAudio = audio

    return playing
  }

  function stopAudio(audio) {
    audio.pause()
    audio.currentTime = 0
    setPlaying(audio, false)
    currentAudio = undefined
  }

  function setPlaying(audio, isPlaying) {
    playing = isPlaying

    const container = element.querySelector(`#${audio.getAttribute('for')}`)

    audio.classList.toggle(audioPlayingClass, isPlaying)
    container.classList.toggle(highlightClass, isPlaying)
  }

  function saveAutoPlaySetting(autoPlayEnabled) {
    up.request(saveSettingUrl, { method: 'PATCH', params: { read_aloud: autoPlayEnabled } })
  }

  function trackPlayCountAfterFirstStop() {
    if (userPressedStop) {
      playCountAfterFirstStop++
    }
  }

  function trackPlayCountAfterFirstFinish() {
    if (allAudiosPlayed) {
      playCountAfterFirstFinish++
    }
  }

  function afterSetPagePlayed(response) {
    const { count, changed } = response.json
    if (changed && TRACK_PAGE_READ_ALOUD_COUNTS.includes(count)) {
      trackEvent('read-aloud', 'pages-read-aloud-count', count)
    }
  }

  function showPlayErrorDialog() {
    up.layer.open({
      content: `
        <h3 class="text-danger text-center">Hoppla!</h3>
        <p>Leider funktioniert das Vorlesen auf deinem Gerät nicht richtig.</p>
        <p>Bitte überprüfe deine Internetverbindung und Browser-Einstellungen.</p>
        <button class="btn btn-primary" type="button" up-dismiss>Schließen</button>
      `,
      size: 'auto',
    })
  }

  trackEvent('read-aloud', 'readable-content-present')

  up.on(element, 'click', startButtonSelector, onClickButton)
  up.on(element, 'click', stopButtonSelector, onClickButton)

  audios.forEach((audio, index) => {
    audio.addEventListener('ended', () => {
      onAudioEnded(audio, index)
    })
  })

  updateVisibleButtonText()

  if (autoPlay) {
    try {
      await play({ throwError: true }).then(updateVisibleButtonText)
      trackEvent('read-aloud', 'auto-play')
    } catch {
      // Autoplay was prevented
      trackEvent('read-aloud', 'auto-play-prevented')
    }
  }

})

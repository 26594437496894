export default class Question {
  constructor(element, userName) {
    this.element = element
    this.userName = userName
    this.answersSetInResults = false
  }

  checkAnswers() {
    const choices = this.element.querySelector('[question--choices]')
    const results = this.element.querySelector('[question--results]')

    const inputs = choices.querySelectorAll('input')

    let allCorrect = true

    for (const input of inputs) {
      const checked = input.checked
      const index = input.dataset.index
      const expectChecked = input.dataset.correct === 'true'

      results.querySelector(`[question--answer-${index}="${checked}"]`).removeAttribute('hidden')

      if (checked !== expectChecked) {
        allCorrect = false
      }

      input.disabled = true
    }

    if (allCorrect) {
      this.setNotification('success', this.getRandomSuccessMessage())
    } else {
      this.setNotification('failure', this.getRandomFailureMessage())
    }

    this.answersSetInResults = true
    this.showSolution()
  }

  setNotification(state, message) {
    const notification = this.element.querySelector('[question--notification]')
    notification.innerText = message
    notification.className = notification.className.replace(/(^|\s)-\w+/g, '') // remove all modifiers
    notification.classList.add(`-${state}`)
  }

  getRandomSuccessMessage() {
    switch (Math.floor(Math.random() * 3)) {
      case 0:
        return `Prima – alles richtig, ${this.userName}! Unten findest du dazu eine Erklärung.`
      case 1:
        return `Klasse, das stimmt, ${this.userName}! Eine Erklärung findest du unten.`
      default:
        return `Gut gemacht, ${this.userName}! Unten findest du eine Erklärung zu der Frage.`
    }
  }

  getRandomFailureMessage() {
    switch (Math.floor(Math.random() * 3)) {
      case 0:
        return `Schade, ${this.userName}! Das ist leider nicht richtig. Sieh dir die Erklärung genau an.`
      case 1:
        return `Leider nein, ${this.userName}! Das ist nicht richtig. Lies dir die Erklärung gut durch.`
      default:
        return `Das stimmt so nicht, ${this.userName}! Schau dir die Erklärung unten gut an.`
    }
  }

  showSolution() {
    if (!this.answersSetInResults) {
      this.element.querySelector('[question--results]').classList.add('-no-answers')
    }

    this.element.querySelector('[question--choices]')?.setAttribute('hidden', true)

    this
      .element
      .querySelectorAll('[question--revealable]')
      .forEach((hiddenElement) => {
        hiddenElement.removeAttribute('hidden')
      })
  }

  skipToSolution() {
    this.showSolution()
  }
}

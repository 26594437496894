import { setFormattedValue } from '../util/fix_caret_position'

up.compiler('[login-code-field]', (element, { hintSelector, invalidPattern, groupSize, groupSeparator }) => {

  const invalidPatternRegExp = new RegExp(invalidPattern, 'g')

  const groupSplitRegExp = new RegExp(`(.{1,${groupSize}})`, 'g')

  const hint = document.querySelector(hintSelector)
  hint?.setAttribute('hidden', true)

  let elementValueWas

  class Formatter {
    constructor(inputValue) {
      const upperCased = inputValue.toUpperCase().replaceAll(groupSeparator, '')
      const sanitized = upperCased.replace(invalidPatternRegExp, '')

      const fragments = sanitized.match(groupSplitRegExp) || []
      if (fragments[fragments.length - 1]?.length === groupSize) {
        fragments.push('') // empty fragment to add trailing space when last group is at full size
      }

      this.fixedInvalidCharacters = upperCased !== sanitized
      this.formattedValue = fragments.join(groupSeparator)
    }
  }

  function onInput() {
    const formatter = new Formatter(element.value)

    if (elementValueWas === `${element.value} `) {
      // user removed trailing space; do nothing
    } else {
      setFormattedValue(element, formatter.formattedValue)

      if (formatter.fixedInvalidCharacters && hint?.hasAttribute('hidden')) {
        hint?.removeAttribute('hidden')
      }
    }

    elementValueWas = element.value
  }

  element.addEventListener('input', onInput)

})

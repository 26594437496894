up.compiler('.full-avatar', (element) => {

  const image = element.querySelector('img')
  let animating = false

  async function onClick() {
    if (animating) return

    animating = true
    const animations = [bounce, flip, wheelie]
    const animation = animations[Math.floor(Math.random() * animations.length)]

    await animation()
    image.style.removeProperty('transform')
    image.style.removeProperty('transform-origin')
    animating = false
  }

  async function bounce() {
    async function bounceBy(offset, duration) {
      if (!image.isConnected) return Promise.resolve()

      await up.animate(image, { transform: `translateY(-${offset}px)` }, { duration: duration / 2 })
      return up.animate(image, { transform: 'translateY(0)' }, { duration: duration / 2 })
    }

    await bounceBy(100, 240)
    await bounceBy(60, 120)
    await bounceBy(30, 60)
    return bounceBy(15, 30)
  }

  async function flip() {
    return up.animate(image, { transform: 'rotateY(-720deg)' }, { duration: 1080 })
  }

  async function wheelie() {
    async function turnBy(degrees, duration) {
      if (!image.isConnected) return Promise.resolve()

      await up.animate(image, { transform: `rotate(-${degrees}deg)` }, { duration: duration * 2 / 3 })
      return up.animate(image, { transform: 'rotate(0)' }, { duration: duration * 1 / 3 })
    }

    image.style.setProperty('transform-origin', 'left bottom')
    await turnBy(25, 360)
    return turnBy(5, 60)
  }

  element.addEventListener('click', onClick)

})

import { gameState } from '../util/game_state'

up.compiler('[course-navigation-submit]', (element) => {
  function setButtonState() {
    element.classList.toggle('btn-visually-disabled', gameState.gameRunning)
  }

  up.on(element, 'click', function() {
    if (element.classList.contains('btn-visually-disabled')) {
      if (confirm('Möchtest du das Spiel abgeben?')) {
        gameState.submit.trigger()
      }
      event.preventDefault()
    }
  })

  setButtonState()
  gameState.changeRunning.register(setButtonState)

  return function destroy() {
    gameState.changeRunning.unregister(setButtonState)
  }
})

import { setFormattedValue } from '../util/fix_caret_position'

up.compiler('[code-search-field]', (element, { invalidPattern }) => {

  const formatRegExp = new RegExp(invalidPattern, 'g')

  function format(value) {
    return value.toUpperCase().replace(formatRegExp, '')
  }

  function onInput() {
    const formattedValue = format(element.value)
    setFormattedValue(element, formattedValue)
  }

  element.addEventListener('input', onInput)

})

import Sortable from '../util/sortable'
import GridGame from '../util/grid_game'
import NuggetFinder from '../util/nugget_finder'
import { gameState } from '../util/game_state'
import { setNotification } from '../util/set_notification'

up.compiler('[game-slide-grid]', (element, { text, columnSettings, columnCount, html, skipToSolution, completeSlideUrl }) => {
  const container = up.element.affix(element, '.grid-game-layout')
  up.element.affix(container, '.grid-game-layout--text[role="heading"]', { text })

  const gridSolution = up.element.createFromHTML(html)
  gridSolution.querySelectorAll('.-draggable').forEach((element) => { element.classList.remove('-draggable') })
  gridSolution.querySelectorAll('.grid-game--column-hint').forEach((element) => { element.classList.add('-hidden') })

  const gridContainer = up.element.createFromHTML(html)
  container.appendChild(gridContainer)

  const controlsContainer = up.element.affix(container, '.grid-game-layout--controls')
  const submitButton = up.element.affix(controlsContainer, 'button.btn.btn-primary', { type: 'button', text: 'Prüfen' })

  const sortableColumns = columnSettings.map(setting => setting.shuffle)
  const rowAttribute = 'data-row'
  const columnIndexAttribute = 'data-column'
  const gridElementSelector = '.grid-game-nugget'
  const draggableElementSelector = '.grid-game-nugget.-draggable'
  const gameNuggets = element.querySelectorAll(draggableElementSelector)
  let submitted = false

  const gridGame = new GridGame(
    columnCount,
    gridContainer,
    gridSolution,
    sortableColumns,
    rowAttribute,
    columnIndexAttribute,
    gridElementSelector,
    draggableElementSelector,
  )

  const swappables = createSwappables()

  up.on(element, 'click', '.grid-game--column-hint[title]', onClickColumnHint)
  submitButton.addEventListener('click', onClickSubmit)
  up.on(gameNuggets, 'click', draggableElementSelector, handleClickOnGameNugget)
  gameState.submit.register(onClickSubmit)

  if (skipToSolution) {
    submit()
    displaySolution()
  }

  function createSwappables() {
    const swappables = []
    const groupPrefix = `game-${Math.random()}`

    for (const i in sortableColumns) {
      const groupSelector = `[${columnIndexAttribute}="${i}"]`
      const swappableElements = element.querySelectorAll(groupSelector)
      const containsDraggables = Array.from(swappableElements).some(element => element.querySelector(draggableElementSelector))

      if (!containsDraggables) {
        continue
      }

      swappableElements.forEach((swappableElement) => {
        const sortable = new Sortable(swappableElement, {
          group: `${groupPrefix}-${groupSelector}`,
          draggable: draggableElementSelector,
          onEnd: onSwap,
          swap: true,
          swapThreshold: 1,
          invertSwap: false,
          animation: 150,
        })
        swappables.push(sortable)
      })
    }
    return swappables
  }

  function handleClickOnGameNugget(event, clickedNugget) {
    up.layer.open({
      content: modalForGameNuggets(clickedNugget),
      dismissable: true,
      onAccepted: (event) => {
        swapGameNuggets(clickedNugget, event.value)
      },
    })
  }

  function modalForGameNuggets(excludedNugget) {
    const gameNuggetsModal = up.element.createFromSelector('.grid-game-modal')

    for (const nugget of gameNuggets) {
      if (nugget !== excludedNugget) {
        const nuggetCopy = up.element.createFromHTML(nugget.outerHTML)
        gameNuggetsModal.appendChild(nuggetCopy)
      }
    }

    up.on(gameNuggetsModal, 'click', draggableElementSelector, (event, clickedNuggetCopy) => {
      const originalNugget = new NuggetFinder(gameNuggets).findByCopy(clickedNuggetCopy)
      up.layer.accept(originalNugget)
    })

    return gameNuggetsModal
  }

  function swapGameNuggets(nuggetOne, nuggetTwo) {
    const gameCellOne = nuggetOne.parentNode
    const gameCellTwo = nuggetTwo.parentNode

    gameCellOne.appendChild(nuggetTwo)
    gameCellTwo.appendChild(nuggetOne)
    onSwap({ gameCellOne, gameCellTwo })
  }

  function onSwap({ from, to }) { // `from` and `to` reference the cells. Items inside would be `item` and `swapItem`.

    if (from === to) {
      // did not actually swap
      return
    }

    const column = to.dataset.column
    const hintSelector = `.grid-game--column-hint[data-hinted-column="${column}"]`
    const hint = gridContainer.querySelector(hintSelector)
    hint?.classList?.add('-muted')
  }

  async function onClickSubmit() {
    submit()
    if (completeSlideUrl) {
      await up.request(completeSlideUrl, { method: 'POST' })
    }
    showSolutionButton()
    if (gridGame.isCompleted) {
      displaySuccessText()
    } else {
      displayFailureText()
    }
  }

  function submit() {
    submitButton.remove()
    destroySwappables()
    gameState.stopGame()
    up.off(gameNuggets, 'click', draggableElementSelector, handleClickOnGameNugget)
    submitted = true
  }

  function onClickColumnHint() {
    alert(this.title)
  }

  function destroySwappables() {
    swappables.forEach(swappable => swappable.destroy())
    swappables.length = 0
  }

  function destroy() {
    gameState.submit.unregister(onClickSubmit)
    if (!submitted) {
      gameState.stopGame()
    }
    destroySwappables()
  }

  function showSolutionButton() {
    const showSolutionButton = up.element.affix(controlsContainer, 'button.btn.btn-primary', { type: 'button', text: 'Lösung anzeigen' })
    showSolutionButton.addEventListener('click', displaySolutionInModal)
  }

  function displaySuccessText() {
    setNotification(element, 'success', 'Prima! Das hast du gut gemacht. Du hast alle Spalten richtig sortiert. Klicke jetzt unten auf „Weiter“, um fortzufahren.')
  }

  function displayFailureText() {
    setNotification(
      element,
      'failure',
      'Schade — leider nicht ganz richtig! Schau dir bitte die Lösung an und klicke dann unten auf „Weiter“, um fortzufahren.',
    )
  }

  async function displaySolutionInModal() {
    const solutionContainer = document.createElement('div')
    up.element.affix(solutionContainer, 'h1', { text: 'Lösung' })
    solutionContainer.appendChild(gridSolution)
    up.element.affix(solutionContainer, 'button.btn.btn-primary.mt-3', { type: 'button', text: 'Meine Antwort anzeigen', 'up-dismiss': true })

    await up.layer.open({ content: solutionContainer, size: 'auto' })
  }

  function displaySolution() {
    gridContainer.replaceWith(gridSolution)
  }

  return destroy
})

import '../../shared/config/unpoly.js'
import '../../shared/config/unpoly.sass'
import { signedIn } from '../util/current_user'

up.link.config.preloadSelectors.push('a[href]')

up.on('up:request:loaded', ({ response }) => {
  if (response.status === 401 && signedIn()) {
    // User was signed out from a previously active session.
    // The 401 response includes an error message that should be displayed.

    up.util.task(() => {
      // We delay render cycle so that up:fragment:loaded events (e.g. form submission)
      // don't have to render results on a DOM that does not match their expectations
      // which can result in errors.
      // This means that a form may render the 401 response considering its failTarget
      // for a single frame until we render the the error response as a "full" page.
      // This is usually not noticeable, and in any case only relevant for edge cases.
      up.render({
        // close any layers that might be open
        layer: 'root',
        peel: true,

        // discard the entire layout, as it might contain information from the user session (e.g. name in header)
        target: 'body',
        document: response.text,
        clearCache: true,

        // discard the current URL to avoid leaking personalized URLs
        location: '/',
        history: true,
      })
    })
  }
})

up.compiler('[full-screen-toggle]', (elem) => {
  function toggleFullscreen(evt) {
    const container = evt.target.closest('main.container')

    if (container.classList.contains('-fullscreen')) {
      exitFullScreen()
      container.classList.remove('-fullscreen')
    } else {
      enterFullScreen(container)
      container.classList.add('-fullscreen')
    }
  }

  up.on(elem, 'click', toggleFullscreen)
  up.on('fullscreenchange', closeFullscreenOnKeyEvent)
  up.on('webkitfullscreenchange', closeFullscreenOnKeyEvent)
  up.on('mozfullscreenchange', closeFullscreenOnKeyEvent)
  up.on('MSFullscreenChange', closeFullscreenOnKeyEvent)

  function closeFullscreenOnKeyEvent(evt) {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      exitFullScreen()
      const container = evt.target.closest('main.container')
      container.classList.remove('-fullscreen')
    }
  }

  function exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  }

  function enterFullScreen(elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen()
    }
  }
})

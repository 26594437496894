import Question from '../util/question'
import { gameState } from '../util/game_state'
import VideoPlayButton from '../util/video_play_button'

up.compiler('[video-multiple-choice]', (element, { name, buttonMode, skipToSolution, completeSlideUrl }) => {
  const multipleChoice = new Question(element, name)
  const questionVideo = element.querySelector('[question--question-video]')
  const solutionVideo = element.querySelector('[question--solution-video]')
  const choices = element.querySelector('[question--choices]')
  const startButton = element.querySelector('[question--start-button]')
  const submitButton = element.querySelector('[question--submit-button]')
  const replayButton = element.querySelector('[question--replay-button]')
  const choiceButtons = element.querySelectorAll('[question--choice-button]')

  let submitted = false

  up.on(questionVideo, 'ended', showChoicesAndAdjustControls)
  up.on(submitButton, 'click', onClickSubmit)
  up.on(choiceButtons, 'click', onClickChoice)
  up.on(replayButton, 'click', replayVideo)
  gameState.submit.register(onClickSubmit)

  const videoPlayButton = new VideoPlayButton(questionVideo, startButton)
  videoPlayButton.connect()

  if (skipToSolution) {
    submit()
    showChoicesAndAdjustControls()
    multipleChoice.skipToSolution()
    showAndPlaySolutionVideo()
  }

  function showChoicesAndAdjustControls() {
    choices.removeAttribute('hidden')
    replayButton.removeAttribute('hidden')
    startButton.setAttribute('hidden', true)
    questionVideo.controls = false

    if (!buttonMode) {
      submitButton.removeAttribute('hidden')
    }
  }

  async function onClickChoice() {
    checkChoice(this.getAttribute('answer_id'))
    onClickSubmit()
  }

  function checkChoice(answerId) {
    const choiceCheckbox = choices.querySelector(`[id="${answerId}"]`)
    choiceCheckbox.checked = true
  }

  async function onClickSubmit() {
    submit()
    if (completeSlideUrl) {
      await up.request(completeSlideUrl, { method: 'POST' })
    }
    multipleChoice.checkAnswers()
    showAndPlaySolutionVideo()
  }

  function submit() {
    submitButton.remove()
    gameState.stopGame()
    submitted = true
  }

  function showAndPlaySolutionVideo() {
    questionVideo.setAttribute('hidden', true)
    replayButton.setAttribute('hidden', true)
    solutionVideo.removeAttribute('hidden')

    solutionVideo.play().catch(() => {
      // User probably did not interact with the page yet. Browsers do not allow video playback in that case,
      // and there is nothing we can do except silencing the error.
    })
  }

  function replayVideo() {
    replayButton.setAttribute('hidden', true)
    questionVideo.controls = true
    questionVideo.play()
  }

  return function destroy() {
    gameState.submit.unregister(onClickSubmit)
    videoPlayButton.disconnect()
    if (!submitted) {
      gameState.stopGame()
    }
  }
})

let currentRelease
let lastNotified

const newReleaseMessage = `
  <div style="max-width: 50ch">
    <p class="lead text-center text-sm-start">
      Es gibt Änderungen an der <span class="text-nowrap">Radfahrausbildung-App.</span>
      <br>
      Bitte lade bei nächster <span class="text-nowrap">Gelegenheit neu!</span>
    </p>
    <p class="text-muted mb-2">
      Beachte: Du bleibst angemeldet und kannst sofort weitermachen.
    </p>
    <p class="text-muted mb-4">
      Wenn du nicht möchtest, musst du nicht jetzt neu laden. Die Anwendung funktioniert dann aber vielleicht nicht richtig.
    </p>

    <p class="d-flex flex-wrap align-items-center justify-content-center gap-2">
      <button type="button" class="btn btn-primary" up-accept>Jetzt neu laden</button>
      <button type="button" class="btn btn-secondary" up-dismiss>Lieber später</button>
    </p>
  </div>
`

up.compiler('[app-status]', (element, { release, frequency }) => {

  currentRelease ??= release
  frequency ??= 30000

  const time = new Date().getTime()

  function recentlyNotified() {
    return lastNotified && (time - lastNotified) < frequency
  }

  function showReloadNotification() {
    up.layer.open({
      content: newReleaseMessage,
      size: 'auto',
      onAccepted: () => { location.reload() },
    })
  }

  if (currentRelease !== release && !recentlyNotified()) {
    showReloadNotification()
    lastNotified = time
  }

})

export function setFormattedValue(element, newValue) {
  const { value: oldValue, selectionStart: caretPosition } = element

  element.value = newValue
  return fixCaretPosition(element, oldValue, caretPosition)
}

export function fixCaretPosition(element, oldValue, caretPosition) {
  // if user entered invalid characters, move their caret back accordingly
  const lengthWas = oldValue.length
  const lengthIs = element.value.length
  const newCaretPosition = caretPosition + (lengthIs - lengthWas)

  element.setSelectionRange(newCaretPosition, newCaretPosition)

  return newCaretPosition !== caretPosition
}

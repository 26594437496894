const scriptsLoaded = {}

export default function loadScript(url) {

  function createScriptTag(url) {
    const scriptTag = document.createElement('script')
    scriptTag.src = url
    return scriptTag
  }

  const cachedPromise = scriptsLoaded[url]
  if (cachedPromise) {
    return cachedPromise
  } else {
    const promise = new Promise((resolve, reject) => {
      const scriptTag = createScriptTag(url)
      scriptTag.addEventListener('load', resolve)
      scriptTag.addEventListener('error', reject)
      document.head.appendChild(scriptTag)
    })
    scriptsLoaded[url] = promise
    return promise
  }

}

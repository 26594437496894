import getDeviceType from './device_type'

export function trackEvent(...eventData) {
  push('trackEvent', ...eventData)
}

export function trackPageView({
  identity,
  resetIdentity,

  status,
  title,
  path,
  referrer,

  demo,
  batchKey,
  region,

  courseUnit,
  coursePage,
  testQuestion,
  slideContentType,
}) {
  if (resetIdentity) {
    push('resetUserId')
  }
  push('setUserId', identity)
  push('setDocumentTitle', title)
  push('setCustomUrl', path)

  if (referrer) {
    push('setReferrerUrl', referrer)
  }

  setCustomDimension(1, demo)
  setCustomDimension(2, batchKey)
  setCustomDimension(3, status)
  setCustomDimension(4, courseUnit)
  setCustomDimension(5, coursePage)
  setCustomDimension(6, testQuestion)
  setCustomDimension(7, region)
  setCustomDimension(8, getDeviceType())
  setCustomDimension(9, slideContentType)

  push('trackPageView')
}

function push(...values) {
  window._paq ??= []
  window._paq.push(values)
}

function setCustomDimension(id, value) {
  if (value !== undefined) {
    push('setCustomDimension', id, value)
  }
}

import VideoPlayButton from '../util/video_play_button'

up.compiler('[video-slide]', (element) => {

  const video = element.querySelector('video')
  const playButton = element.querySelector('[video-slide--play-button]')

  const videoPlayButton = new VideoPlayButton(video, playButton)
  videoPlayButton.connect()

  return () => {
    videoPlayButton.disconnect()
  }

})
